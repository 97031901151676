<template>
  <el-dialog
    top="20px"
    class="el-dialog-cus"
    v-bind="attributes"
    :visible="visible"
    :before-close="beClose"
    append-to-body
    :close-on-click-modal="true"
    v-on="on"
  >
    <div class="pop-info-box">
      <h5 class="pop-tits">
        <span>上传证件照</span>
        <el-link class="custon-close" @click="cancel"><i class="el-icon-close"></i></el-link>
      </h5>
      <div class="upload-box">
        <div class="up-id-box">
          <ul class="up-list">
            <li v-for="(item, index) in imgList" :key="index">
              <div class="up-img-box" v-loading="loading[index]">
                <span
                  class="id-img"
                  :style="{
                    backgroundImage: 'url(' + (item.imgPreview ? item.imgPreview : item.bg) + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                  }"
                ></span>
                <i class="camalar-i"></i>
                <i class="xx-k"></i>
                <input type="file" class="up-file" @change="file => uploadPic(file, index)" accept="image/*" />
              </div>
              <p>{{ item.text }}</p>
            </li>
          </ul>
        </div>
        <p class="up-setion">拍摄上传要求：拍摄时确保照片人物与字体清晰，光线均匀无反光</p>
        <ul class="img-set-box">
          <li v-for="item in imgsetList" :key="item.index">
            <span
              class="img-set"
              :style="{
                backgroundImage: 'url(' + item.img + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }"
            ></span>
            <p>{{ item.text }}</p>
          </li>
        </ul>
        <el-button class="sure-btns" @click="submitForm">确认提交</el-button>
      </div>
    </div>

    <!-- <div slot="footer">
      <el-button @click="cancel" plain>{{btnTxt[0]}}</el-button>
      <el-button @click="confirm" type="primary" v-if="btnTxt[1]">{{btnTxt[1]}}</el-button>
    </div> -->
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    config: {
      type: Object,
      default: () => ({
        width: '420px',
        title: '提示',
        center: true,
        btnTxt: ['取消', '确定']
      })
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    const { config } = this,
      { btnTxt } = config
    return {
      loading: {
        0: false,
        1: false,
        2: false,
        3: false
      },
      visible: false,
      attributes: config,
      btnTxt,
      on: this.getDialogEvents(),

      ruleForm: {
        name: '',
        phone: '',
        id: '',
        mesaage: '',
        system: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入传承人姓名', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        id: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        mesaage: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        system: [{ required: true, message: '自动识别匹配传承人非遗项目', trigger: 'blur' }]
      },
      imgList: [
        {
          key: 'idcardFrontImg',
          img: '',
          imgPreview: '',
          bg: require('../../assets/images/id-img1.png'),
          text: '上传身份证正面'
        },
        {
          key: 'idcardBackImg',
          img: '',
          imgPreview: '',
          bg: require('../../assets/images/id-img2.png'),
          text: '上传身份证反面'
        },
        {
          key: 'idcardHandImg',
          img: '',
          imgPreview: '',
          bg: require('../../assets/images/id-img3.png'),
          text: '上传手持身份证正面'
        },
        {
          key: 'personalImg',
          img: '',
          imgPreview: '',
          bg: require('../../assets/images/id-img4.png'),
          text: '上传个人一寸彩照'
        }
      ],
      imgsetList: [
        {
          img: require('../../assets/images/up-img1.png'),
          text: '标准样式'
        },
        {
          img: require('../../assets/images/up-img2.png'),
          text: '边框消失照片倾斜'
        },
        {
          img: require('../../assets/images/up-img3.png'),
          text: '模糊不清晰'
        },
        {
          img: require('../../assets/images/up-img4.png'),
          text: '反光闪光看清'
        }
      ]
    }
  },
  methods: {
    uploadPic(event, index) {
      this.loading[index] = true
      let formData = new FormData()
      let file = event.target.files[0]
      formData.append('file', file)
      this.axios
        .post('/api/portal/blog/auth/put-file', formData)
        .then(res => {
          this.imgList[index].img = res.data.link
          let reader = new FileReader()
          reader.onload = () => {
            this.imgList[index].imgPreview = reader.result
          }
          reader.readAsDataURL(file)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loading[index] = false
          })
        })
    },
    submitForm() {
      let pics = {}
      for (const item of this.imgList) {
        if (item.img) {
          pics[item.key] = item.img
        } else {
          this.$message.warning('请上传完整照片！')
          return false
        }
      }
      this.$emit('update:inheritorPics', pics)
      this.$emit('submit')
    },

    open(ok) {
      this.ok = ok
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    confirm() {
      let cancel = () => this.cancel()
      this.ok(cancel)
      this.autoClose && cancel()
    },
    beClose(done) {
      done()
      this.beforeClose()
      this.cancel()
    },
    getDialogEvents() {
      let { close } = this.config || {},
        events = {}

      if (close && typeof close == 'function') {
        Object.assign(events, {
          close
        })
      }

      return events
    }
  }
}
</script>

<style lang="scss">
.el-dialog__header {
  display: none;
}
.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.el-dialog {
  margin-bottom: 0px !important;
  background: transparent !important;
}
</style>
