var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',_vm._g(_vm._b({staticClass:"el-dialog-cus",attrs:{"top":"20px","visible":_vm.visible,"before-close":_vm.beClose,"append-to-body":"","close-on-click-modal":true}},'el-dialog',_vm.attributes,false),_vm.on),[_c('div',{staticClass:"pop-info-box"},[_c('h5',{staticClass:"pop-tits"},[_c('span',[_vm._v("上传证件照")]),_c('el-link',{staticClass:"custon-close",on:{"click":_vm.cancel}},[_c('i',{staticClass:"el-icon-close"})])],1),_c('div',{staticClass:"upload-box"},[_c('div',{staticClass:"up-id-box"},[_c('ul',{staticClass:"up-list"},_vm._l((_vm.imgList),function(item,index){return _c('li',{key:index},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading[index]),expression:"loading[index]"}],staticClass:"up-img-box"},[_c('span',{staticClass:"id-img",style:({
                  backgroundImage: 'url(' + (item.imgPreview ? item.imgPreview : item.bg) + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
                })}),_c('i',{staticClass:"camalar-i"}),_c('i',{staticClass:"xx-k"}),_c('input',{staticClass:"up-file",attrs:{"type":"file","accept":"image/*"},on:{"change":function (file) { return _vm.uploadPic(file, index); }}})]),_c('p',[_vm._v(_vm._s(item.text))])])}),0)]),_c('p',{staticClass:"up-setion"},[_vm._v("拍摄上传要求：拍摄时确保照片人物与字体清晰，光线均匀无反光")]),_c('ul',{staticClass:"img-set-box"},_vm._l((_vm.imgsetList),function(item){return _c('li',{key:item.index},[_c('span',{staticClass:"img-set",style:({
              backgroundImage: 'url(' + item.img + ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            })}),_c('p',[_vm._v(_vm._s(item.text))])])}),0),_c('el-button',{staticClass:"sure-btns",on:{"click":_vm.submitForm}},[_vm._v("确认提交")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }